<template>
  <el-card shadow="never">
    <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        style="margin-top: 20px"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="ruleForm.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="介绍" prop="introduction">
        <el-input
            v-model="ruleForm.introduction"
            autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="正文" prop="content">
        <div id="edtor"></div>
      </el-form-item>
      <el-form-item label="产品类别" prop="productTypeId">
        <el-select v-model="productTypeId" placeholder="请选择">
          <el-option
              v-for="item in productOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上传主图" prop="productTypeId">
        <el-upload
            class="upload-demo"
            action="https://sites.zzmeetluyao.com/api/luyao/img/upload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :file-list="fileList"
            :before-remove="beforeRemove"
            :before-upload="beforeUpload"
            :limit="1"
            name="picture"
            list-type="picture"
            style="margin-bottom: 20px"
        >
          <el-button size="small" type="primary">上传主图</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1M，每次仅限上传1张</div>
        </el-upload>
      </el-form-item>

      <el-form-item>
        <el-button
            type="primary"
            @click="addmodular('ruleForm')"
            style="background: #1d90ff; border: none"

        >提交</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import $ from "jquery";
import wangeditor from "wangeditor";
import {baseURL} from '@/utils/ajax.js';
export default {
  methods: {
    handleSuccess(response,file){
      const res=response.data;
      if(res.errno===0){
        const imageUrl=baseURL.replace("/luyao","")+res.data[0];
        const fileName=imageUrl.substring(imageUrl.lastIndexOf("/")+1);
        console.log("fileName",fileName)
        this.fileList.push({"name":fileName,"url":imageUrl})
      }else{
        this.$message("上傳失敗!")
      }
    },
    beforeRemove(file, fileList) {
                //return this.$confirm(`确定移除 ${ file.name }？`);
            },
            //阻止upload的自己上传，进行再操作
            beforeupload(file) {
                return false;
            },
            //覆盖默认的上传行为
            httprequest() {

            },
    beforeUpload(file) {
      this.isLt2k = file.size / 1024 < 10240 ? "1" : "0";
      if (this.isLt2k === "0") {
        this.$message({
          message: "上传文件大小不能超过10M!",
          type: "error",
        });
      }
      return this.isLt2k === "1" ? true : false;
    },
    handleRemove(file, fileList) {
      this.fileList=[];
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    getproductType() {
      this.$api.get(
        "/product/list/type",
        null,
        {
          list: false,
        },
        (successRes) => {
          this.productOptions = successRes.data.rows;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
    addmodular(ruleForm) {
      
      let context = this.editor.txt.html();
      this.$nextTick(() => {
        this.$refs[ruleForm].validateField("street");
      });
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.$api.post(
            "/product/update",
            null,
            {
              content: context,
              productTypeId: this.productTypeId,
              introduction: this.ruleForm.introduction,
              image: this.fileList[0].url,
              title: this.ruleForm.title,
              id: this.id,
              audit:false,
            },
            (successRes) => {
              console.log(successRes);
              if (successRes.status == 200) {
                this.$message("操作成功");
                this.$router.push({name: "modularlist"});
              } else {
                this.$message(successRes.message);
                
              }
            },
            (failureRes) => {
              this.$message("操作失败");
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    init() {
      const articleId = this.$route.query.id;
      this.id = this.$route.query.id;
      this.getproductById(articleId);
    },
     getproductById(id) {
      this.$api.get(
          "/product/getProductById",
          null,
          {id: id},
          (successRes) => {
            console.log(successRes);
            this.ruleForm = successRes.data;
            this.productTypeId= successRes.data.productTypeId;
            this.imgBase64Array = successRes.data.image;
            let obj = {name: 'picture.jpg',url: this.imgBase64Array}
            this.fileList.push(obj)
            console.log(this.fileList)
            this.editor.txt.html(this.ruleForm.content)
            if (successRes.data.status == 3) {
               this.editor.disable();
            }
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
  },
  data() {
    return {
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        introduction: [
          { required: true, message: "请输入介绍", trigger: "blur" },
        ],
      },
      id: 0,
      editor: null,
      ruleForm: {
        title: "",
        content: "",
        introduction: "",
      },
      productTypeId: "",
      productOptions: [],
      fileList:[],
      imgBase64Array: '',
    };
  },
  mounted() {
    const editor = new wangeditor("#edtor");
    editor.config.onchange = function (newHtml) {
      console.log("change 之后最新的 html", newHtml);
    };
    editor.config.uploadImgServer = baseURL+"/img/upload";
    editor.config.uploadFileName = "picture";
   
    (editor.config.uploadImgHooks = {
      customInsert: function (insertImgFn, result) {
        // result 即服务端返回的接口
        console.log("customInsert", result);
        // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
        insertImgFn(baseURL.replace("/luyao","")+result.data.data[0]);
      },
    });
    editor.create();
    this.editor = editor;
    this.getproductType();
    this.init();
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
};
</script>
<style>
.w-e-menu{
  z-index: 2 !important;
}
.w-e-text-container{
  z-index: 1 !important;
}
</style>